import { type FunctionComponent, type PropsWithChildren, createContext, useState } from 'react';
import { AnalyticsHelper } from '../../helpers/analytics/analytics.helper';
import { buildProductQuantityChange } from '../../helpers/analytics/gtm/event-builders';
import { type Selection, updateSelectionTotal } from '../../helpers/product-helper/configuration/product-configuration.helper';

type AddToCartContextState = {
	selection?: Selection;
	setSelection: (selection: Selection) => void;
	setCartQuantity: (cartQuantity: number) => void;
};

const initialState: AddToCartContextState = {
	selection: undefined,
	setSelection: () => {},
	setCartQuantity: () => {}
};
// TODO: clean up old experience SODEV-40175 this file needs to go away
export const AddToCartContext = createContext<AddToCartContextState>(initialState);

export const AddToCartContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
	initialState.setSelection = (selection?: Selection) =>
		setState((prevState) => {
			return { ...prevState, selection };
		});
	initialState.setCartQuantity = (quantity) => setAndTrackCartQuantity(quantity);

	const [state, setState] = useState(initialState);

	const setAndTrackCartQuantity = (newQuantity: number) => {
		void AnalyticsHelper.track(buildProductQuantityChange(false));
		setState((prevState) => {
			return prevState.selection
				? {
						...prevState,
						selection: updateSelectionTotal({
							...prevState.selection,
							quantity: newQuantity,
							initialTotal: newQuantity * prevState.selection.price
						})
				  }
				: prevState;
		});
	};

	return <AddToCartContext.Provider value={state}>{children}</AddToCartContext.Provider>;
};
